<template>
  <div class="container">
    <nav class="breadcrumb is-large" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link
            :to="{ name: 'negotiatorStart', params: { id: $route.params.id } }"
          >
            <span>Start</span></router-link
          >
        </li>
        <li class="is-active">
          <router-link
            :to="{
              name: 'negotiatorPayLess',
              params: { id: $route.params.id }
            }"
          >
            <span>Pay Less</span></router-link
          >
        </li>
      </ul>
    </nav>
    <validation-observer v-slot="{ invalid }" class="box">
      <p class="content spinner-wrapper" v-if="pageLoading">
        <span class="icon is-large has-text-info spinner">
          <font-awesome-icon icon="spinner" size="lg" spin />
        </span>
      </p>
      <p
        v-else
        class="content"
        v-for="(msg, index) in negotiatorSOD1Messages"
        :key="index"
      >
        {{ msg }}
      </p>

      <validation-provider
        tag="div"
        class="field"
        v-slot="{ errors, failed }"
        rules="required|max:255"
      >
        <label class="label">{{ instanceConfig.offerAmountLabel }}</label>
        <div class="control has-icons-left ">
          <input
            :disabled="pageLoading"
            type="text"
            class="input"
            v-model="offerAmount"
            :name="instanceConfig.offerAmountLabel"
          />
          <span class="icon is-small is-left">
            <font-awesome-icon icon="dollar-sign" size="sm" />
          </span>
          <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
        </div>
      </validation-provider>

      <validation-provider
        tag="div"
        class="field"
        v-slot="{ errors, failed }"
        rules="required|max:255"
      >
        <label class="label">{{ instanceConfig.offerDateLabel }}</label>
        <div class="control">
          <input
            :disabled="pageLoading"
            type="date"
            class="input"
            v-model="offerDate"
            :name="instanceConfig.offerDateLabel"
          />
          <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
        </div>
      </validation-provider>

      <div class="field">
        <p class="content"><em>* indicates required field</em></p>
      </div>
      <div class="field">
        <div class="buttons">
          <button
            :disabled="invalid"
            class="button is-success"
            :class="{ 'is-loading': submitting }"
            @click="submit"
          >
            Submit
          </button>
          <router-link
            :to="{ name: 'negotiatorStart', params: { id: $route.params.id } }"
            tag="button"
            class="button is-light"
            >Cancel</router-link
          >
        </div>
      </div>
    </validation-observer>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { mapActions, mapGetters } from "vuex";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import moment from "moment";

extend("required", {
  ...required,
  message: "{_field_} is required."
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  computed: {
    ...mapState(["instanceConfig", "negotiatorSOD2Results"]),
    ...mapGetters(["getFormParam", "getRetVal"])
  },
  methods: {
    ...mapActions([
      "fetchPaymentPortalInstanceConfig",
      "fetchUserInfo",
      "fetchMessages",
      "fetchNegotiatorSOD2FormFields",
      "callNegotiatorSOD2"
    ]),
    ...mapActions("accounts", ["fetchAccounts"]),
    parseAmount(amount) {
      try {
        return amount.replace("$", "").replace(",", "");
      } catch (e) {
        return amount;
      }
    },
    parseDate(d) {
      // date for RETVALs is human-readable so it can be shown in NESTED messages
      try {
        return moment(d, "MM/DD/YYYY").format("YYYY-MM-DD");
      } catch (e) {
        return d;
      }
    },
    async submit() {
      this.submitting = true;
      this.$store.commit("SET_NEGOTIATOR_OFFER_AMOUNT", null);
      this.$store.commit("SET_NEGOTIATOR_OFFER_DATE", null);
      let od = moment(this.offerDate).format("YYYYMMDD");
      try {
        await this.callNegotiatorSOD2({
          sodCode: this.instanceConfig.negotiatorSOD2,
          relatedAccount: this.$route.params.id,
          offerDate: od,
          offerAmount: this.offerAmount
        });
        if (this.negotiatorSOD2Results.success) {
          this.$store.commit("SET_NEGOTIATOR_OFFER_AMOUNT", this.offerAmount);
          this.$store.commit(
            "SET_NEGOTIATOR_OFFER_DATE",
            this.offerDate + "T12:00:00.000"
          );
          this.$router.push({
            name: "negotiatorPayLessSuccess",
            params: { id: this.$route.params.id }
          });
        } else {
          if (this.negotiatorSOD2Results["catch-all"].success) {
            let sodRetVals = this.negotiatorSOD2Results["catch-all"].retvals;
            this.$store.commit(
              "SET_NEGOTIATOR_OFFER_AMOUNT",
              this.parseAmount(
                sodRetVals[this.getRetVal("PORTAL_PAY_LESS_AMOUNT")]
              )
            );
            this.$store.commit(
              "SET_NEGOTIATOR_OFFER_DATE",
              this.parseDate(
                sodRetVals[this.getRetVal("PORTAL_PAY_LESS_DATE")]
              ) + "T12:00:00.000"
            );
            this.$router.push({
              name: "negotiatorPayLessFail",
              params: { id: this.$route.params.id }
            });
          } else {
            this.$router.push({
              name: "negotiatorPayLessSystemError",
              praams: { id: this.$route.params.id }
            });
          }
        }
      } catch (e) {
        this.$store.commit("SET_GLOBAL_NOTIFICATION", {
          showNotification: true,
          notificationMessage: `Error Calling Negotiator Engine; ${e}`,
          notificationType: "error"
        });
      } finally {
        this.submitting = false;
      }
    }
  },
  data() {
    return {
      negotiatorSOD1Messages: [],
      offerAmount: null,
      offerDate: null,
      pageLoading: true,
      submitting: false
    };
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      await vm.fetchPaymentPortalInstanceConfig(vm.$route.params.instanceId);
      if (
        vm.$store.state.userInfo == null ||
        vm.$store.state.userInfo.firstName == null
      ) {
        await vm.fetchUserInfo();
      }
      if (
        vm.$store.state.accounts.accounts == null ||
        vm.$store.state.accounts.accounts.length == 0
      ) {
        await vm.fetchAccounts();
      }
      await vm.fetchNegotiatorSOD2FormFields(
        vm.$store.state.instanceConfig.negotiatorSOD2
      );
      let msgs = await vm.fetchMessages({
        sodCode: vm.$store.state.instanceConfig.negotiatorSOD1,
        relatedAccount: vm.$route.params.id
      });
      let arr = [];
      for (let i = 0; i < msgs.length; i++) {
        arr.push(msgs[i].split("\n"));
      }
      vm.negotiatorSOD1Messages = arr.flat();
      vm.pageLoading = false;
    });
  }
};
</script>
